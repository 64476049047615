import React from 'react';
import styles from '../styles/references.module.scss';

const References = () => (
  <section>
    <ul className={styles.referenceList}>
      <li className={styles.reference}>
        <blockquote cite="https://www.linkedin.com/in/luke-crouch-b3013812b/">
          <p className={styles.reference__quote}>
            Corey is the epitome of a great developer - he's an expert in his
            field, and constantly strives to learn tools inside and outside of
            his profession that could help him build an even stronger skillset.
            His ability to understand design fundamentals from a development
            perspective, his precision and speed, and his "can-do" attitude go a
            long way in making him one of my favorite developers I've had the
            pleasure of working with.
          </p>
        </blockquote>
        <cite className={styles.reference__source}>
          <a
            className={styles.reference__author}
            href="https://www.linkedin.com/in/luke-crouch-b3013812b/"
          >
            Luke Crouch
          </a>
          , Senior Digital Designer at Red Ventures
        </cite>
      </li>
      <li className={styles.reference}>
        <blockquote cite="https://www.linkedin.com/in/samuel-reid-222188150/">
          <p className={styles.reference__quote}>
            I've watched Corey grow a great deal in a relatively short amount of
            time at RV. When he first joined my team he was already an adept and
            meticulous coder, but as he swiftly evolved from Junior to Mid-level
            he has become not only an excellent individual contributor, writing
            world-class code that thoroughly embraces modern front-end standards
            and best practices, but also an inspirational mentor and people
            leader. He is a known resource for many front-end technologies and
            has become a go-to member of the team for his knowledge and
            expertise. Not to mention that he's consistently a joy to be around
            and an absolute pleasure to work with; he works extremely hard but
            very much knows when to have fun too!
          </p>
        </blockquote>
        <cite className={styles.reference__source}>
          <a
            className={styles.reference__author}
            href="https://www.linkedin.com/in/samuel-reid-222188150/"
          >
            Samuel Reid
          </a>
          , Director of Front-End Development at Red Ventures
        </cite>
      </li>
      <li className={styles.reference}>
        <blockquote cite="https://www.linkedin.com/in/bailey-hood/">
          <p className={styles.reference__quote}>
            Corey is the ideal person you want to work with: he's got an
            infectious curiosity that inspires those he works with, a strong eye
            for detail without losing sight of the big picture, and an overall
            great work ethic. Corey is always willing to helps others and is a
            natural at assisting newer team members prepare and work through web
            development and business challenges. You can always rely on him for
            producing high quality work, giving detailed feedback on code
            reviews, and planning and scoping out upcoming initiatives. Oh, and
            he's your go-to person for movie and music recommendations!
          </p>
        </blockquote>
        <cite className={styles.reference__source}>
          <a
            className={styles.reference__author}
            href="https://www.linkedin.com/in/bailey-hood/"
          >
            Bailey Hood
          </a>
          , Senior Front-End Developer at Red Ventures
        </cite>
      </li>
      <li className={styles.reference}>
        <blockquote cite="https://www.linkedin.com/in/embarnardshao/">
          <p className={styles.reference__quote}>
            Corey is an excellent mentor! He created an open and helpful
            environment and always made sure to reach out to me with technical
            resources, advice and book recommendations. Even though he switched
            to a different team 2/3rds into my internship, he would take time to
            catch up with me. He has a talent for writing clean code and
            challenging his peers to do the same. Corey is also an extremely
            personable teammate and easy to work with. I am sure he will be a
            great member of any team and will always be a great role model
            to&nbsp;me!
          </p>
        </blockquote>
        <cite className={styles.reference__source}>
          <a
            className={styles.reference__author}
            href="https://www.linkedin.com/in/embarnardshao/"
          >
            Emily Barnard
          </a>
          , GetCalFresh Engineer at Code for America
        </cite>
      </li>
    </ul>
  </section>
);

export default References;
