import React from 'react'
import Layout from '../components/layout'
import References from '../components/references'

const ReferencesPage = props => (
  <Layout>
    <References />
  </Layout>
)

export default ReferencesPage
